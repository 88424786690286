<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#097392"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#097392" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <!-- <v-parallax
      style="opacity: 0.8"
      dark
      :src="require('./../../assets/images/A2.jpg')"
    > -->
    <v-layout wrap justify-start v-if="about">
      <v-flex xs12 align-self-start v-if="about.image">
        <v-img
          cover
          height="450px"
          class="align-end"
          :src="mediaURL + about.image"
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
          <v-layout wrap justify-start>
            <v-flex py-16 xs12 align-self-center text-center>
              <span :style="'color:' + about.textColor" class="gall"
                >ABOUT US</span
              >
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pa-4 pa-sm-12 pa-xl-16>
      <v-flex xs12 align-self-center>
        <v-layout justify-center wrap>
          <v-flex xs12 sm12 md12 lg10 align-self-center text-justify>
            <span
              style="font-family: TitLight; font-size: 25px; color: black"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '14px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '24px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '24px'
                    : '35px',
              }"
              >{{ about.text }}
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- </v-parallax> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      about: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/home/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.about = response.data.data.aboutUs;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
</style>